import cls from 'classnames';
import { FC, memo, useEffect, useMemo, useRef, useState } from 'react';

import { AudioTimeUpdateEvent } from '@/lib/hooks/use-audio-player';

import styles from './styles';

interface AudioProgressBarProps {
  className?: string;
  isActive: boolean;
  borderWidth: number;
  onPlay: () => void;
  onPause: () => void;
  audioTimeUpdateEvent?: AudioTimeUpdateEvent;
  playFromBeginning?: boolean;
  setDurationTime?: (seconds: number) => void;
  durationSound?: number;
}

const AudioProgressBar: FC<AudioProgressBarProps> = memo(
  ({
    className = '',
    isActive,
    borderWidth = 4,
    onPlay,
    onPause,
    audioTimeUpdateEvent,
    setDurationTime = () => null,
    durationSound,
  }) => {
    const wrapperRef = useRef<HTMLElement | null>(null);
    const [wrapperWidth, setWrapperWidth] = useState(0);

    const radius = useMemo(
      () => (wrapperWidth ? wrapperWidth - borderWidth * 2 - 4 : 0),
      [wrapperWidth, borderWidth],
    );

    const circumference = useMemo(() => radius * 2 * Math.PI, [radius]);

    const [strokeDashoffset, setStrokeDashoffset] = useState<number>(0);

    const onButtonClick = () => (isActive ? onPause() : onPlay());

    useEffect(() => () => setStrokeDashoffset(0), []);

    useEffect(() => {
      // we only care about the audioTimeUpdateEvent if this component isActive
      if (!isActive || !audioTimeUpdateEvent) return;

      const { currentTime, duration, isPaused } = audioTimeUpdateEvent;

      if (!isPaused && durationSound) {
        const percentage = currentTime / durationSound;
        const timeLeft = durationSound - currentTime;

        setDurationTime(timeLeft);
        setStrokeDashoffset(circumference - percentage * circumference);
      } else if (!isPaused) {
        const percentage = currentTime / duration;
        const timeLeft = duration - currentTime;

        setDurationTime(timeLeft);
        setStrokeDashoffset(circumference - percentage * circumference);
      }
    }, [
      audioTimeUpdateEvent,
      circumference,
      isActive,
      setDurationTime,
      durationSound,
    ]);

    useEffect(() => {
      if (!wrapperWidth && wrapperRef.current?.offsetWidth) {
        setWrapperWidth(wrapperRef.current.offsetWidth);
      }
    }, [wrapperWidth, wrapperRef.current?.offsetWidth]);

    return (
      <figure className={cls(styles.audioBubble, className)} ref={wrapperRef}>
        <button
          onClickCapture={onButtonClick}
          className={styles.audioBubbleButton(borderWidth)}
        >
          <svg
            viewBox={`0 0 ${wrapperWidth * 2} ${wrapperWidth * 2}`}
            className={styles.audioBubbleProgress}
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx={wrapperWidth}
              cy={wrapperWidth}
              r={radius}
              strokeWidth={borderWidth * 2}
              fill="none"
              strokeDashoffset={strokeDashoffset || circumference}
              strokeDasharray={circumference}
              className={styles.circle(strokeDashoffset > 0)}
              transform={`rotate(-90) translate(-${wrapperWidth * 2})`}
            />
          </svg>

          <svg className={styles.audioBubblePlayPause} viewBox="0 0 256 256">
            {!isActive ? (
              <path
                fill="#fff"
                d="M 227.467 112.6 L 41.867 2.133 C 39.599 0.8 37.267 0 34.6 0 C 27.333 0 21.399 6 21.399 13.333 L 21.333 13.333 L 21.333 242.667 L 21.399 242.667 C 21.399 250 27.333 256 34.6 256 C 37.333 256 39.599 255.067 42.067 253.734 L 227.467 143.4 C 231.867 139.734 234.666 134.2 234.666 128 C 234.666 121.8 231.867 116.334 227.467 112.6 Z"
              />
            ) : (
              <g
                fill="#fff"
                transform="matrix(0.666667, 0, 0, 0.666667, -42.666752, -42.666759)"
              >
                <path d="M224,435.8V76.1c0-6.7-5.4-12.1-12.2-12.1h-71.6c-6.8,0-12.2,5.4-12.2,12.1v359.7c0,6.7,5.4,12.2,12.2,12.2h71.6   C218.6,448,224,442.6,224,435.8z" />
                <path d="M371.8,64h-71.6c-6.7,0-12.2,5.4-12.2,12.1v359.7c0,6.7,5.4,12.2,12.2,12.2h71.6c6.7,0,12.2-5.4,12.2-12.2V76.1   C384,69.4,378.6,64,371.8,64z" />
              </g>
            )}
          </svg>
        </button>
      </figure>
    );
  },
);

export default AudioProgressBar;
