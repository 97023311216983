import { format } from 'date-fns';
import { FC, memo, useState } from 'react';

import AudioProgressBar from '@/components/audio-progress-bar';
import Image from '@/components/image';
import MultipleTagsChips from '@/components/multiple-tags-chips';
import Share from '@/components/share';
import formatAudioTime from '@/lib/format-audio-time';
import getBaseUrlIfBrowser from '@/lib/get-base-url-if-browser';
import { mapToSlug } from '@/lib/utils';
import { SoundpieceProps } from '@/types/views/generic';

import styles from './styles';

const Soundpiece: FC<SoundpieceProps> = memo(
  ({
    id,
    title,
    audioUrl,
    coverUrl,
    publishDate,
    tags,
    onPlay,
    onPause,
    audioTimeUpdateEvent,
    isActive,
    duration,
  }) => {
    const [durationTime, setDurationTime] = useState<number>(duration);

    // duration is in seconds we display it in minutes
    const formattedDurration = () => {
      const durationTimeInMinutes = Math.round(duration / 60);

      if (durationTimeInMinutes === 1) {
        return `${durationTimeInMinutes} Minute`;
      }

      return `${durationTimeInMinutes} Minuten`;
    };

    const formattedPublishDate = (dateFormat = 'dd.MM.yyyy') =>
      publishDate ? format(new Date(publishDate), dateFormat) : null;

    const areTagsAvailable = !!tags.length;

    const playSoundpiece = () => {
      onPlay();
      const formats = tags.filter((tag) => tag.type === 'format');
      window.dataLayer?.push({
        event: 'soundpiece_play',
        media_title: title,
        media_url: audioUrl,
        media_format: formats.length > 0 ? formats[0].name : '',
      });
    };

    return (
      <li key={id} className={styles.listItem(areTagsAvailable)}>
        <div className={styles.imageWrapper}>
          <Image src={coverUrl} alt={title} className={styles.image} />
        </div>

        <div className={styles.date}>
          <span>{formattedPublishDate()}</span>

          <span className={styles.duration}>{formattedDurration()}</span>

          {isActive && (
            <span className={styles.timeLeft}>
              &nbsp;|&nbsp;-{formatAudioTime(durationTime)}
            </span>
          )}
        </div>

        <div className={styles.actions}>
          <Share
            urlToShare={`${getBaseUrlIfBrowser()}/audios/${id}-${mapToSlug(
              title,
            )}`}
          />

          <AudioProgressBar
            className={styles.audioProgressBar}
            onPlay={playSoundpiece}
            onPause={onPause}
            audioTimeUpdateEvent={audioTimeUpdateEvent}
            isActive={isActive}
            borderWidth={2}
            setDurationTime={setDurationTime}
            durationSound={duration}
          />
        </div>

        <div className={styles.entryTitle}>{title}</div>

        {areTagsAvailable && (
          <ul className={styles.tags}>
            <MultipleTagsChips tags={tags} />
          </ul>
        )}
      </li>
    );
  },
);

export default Soundpiece;
