import { css } from '@emotion/css';
import cls from 'classnames';
import { FC } from 'react';
import theme from 'tailwind/theme';

import useLockBodyScroll from '@/lib/hooks/use-lock-body-scroll';

// TODO: remove one of icon-cross, cross-icon
import CrossIcon from '../icons/icon-cross';
import Portal from '../portal';

interface ModalProps {
  className?: string;
  onClose?: () => void;
  showCloseIcon?: boolean;
}

const modalOverlay = css`
  background-color: rgba(0, 0, 0, 0.5);
`;

const modalContentWrapper = css`
  @media (min-width: ${theme.screens.md}) {
    height: auto;
    max-height: 85vh;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Modal: FC<ModalProps> = ({
  className = '',
  children,
  onClose,
  showCloseIcon = true,
}) => {
  useLockBodyScroll();

  const renderCloseIcon = () => (
    <div
      className={cls(
        'fixed w-8 h-8 bg-white right-6 top-20 flex justify-center items-center rounded-16 cursor-pointer',
        'md:absolute md:top-4 md:right-4 md:z-10',
        'lg:right-6',
      )}
      onClick={onClose}
    >
      <CrossIcon />
    </div>
  );

  return (
    <Portal>
      <div
        className={cls(
          'fixed inset-x-0 bottom-0 top-16 z-60 flex justify-center items-center h-auto',
          modalOverlay,
          className,
        )}
      >
        <div className="md:content-box-centered h-full md:h-auto">
          <div className="relative w-screen md:w-2/3 mx-auto h-full md:h-auto">
            {showCloseIcon && (
              <div className="hidden md:block">{renderCloseIcon()}</div>
            )}

            <div
              className={cls(
                'relative overflow-y-auto overflow-x-hidden md:rounded-4 bg-black-light h-full md:h-auto tesn:bg-esn-secondary',
                modalContentWrapper,
              )}
            >
              {showCloseIcon && (
                <div className="block md:hidden">{renderCloseIcon()}</div>
              )}

              {children}
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default Modal;
