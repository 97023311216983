/* eslint-disable camelcase */

// TODO: merge src/types/apis file and src/types/apis folder
import { TeaserSectionEntry } from '@/types/views/sections';

import { Channel } from './apis/broadcast-manager';

export enum TagType {
  Profile = 'profile',
  Category = 'category',
  Format = 'format',
  Show = 'show',
}

export interface TaggingToolEntry {
  id: number;
}

export interface TaggingToolTag extends TaggingToolEntry {
  title: string;
  contentful_entity_id: string;
  contentful_type_id: TagType;
  position?: number;
}

export interface TaggingToolMeme extends TaggingToolEntry {
  image: string;
  title: string;
  description: string;
  content_category_slugs: TaggingToolContentCategory[];
  contentful_teaser_id: string;
  teaser: unknown;
  published_at: string | null;
  tags: TaggingToolTag[];
  appear_in_watch_experience: boolean | null;
}

interface TaggingToolContentCategory {
  id: number;
  title: string;
  contentful_entity_id: string;
  position: number;
}

export interface TaggingToolVideo extends TaggingToolEntry {
  title: string;
  description: string;
  contentful_teaser_id: string | null;
  jw_video_id: string;
  published_at: string | null;
  appear_in_watch_experience: boolean | null;
  entity_definition_id: number;
  content_category_slugs: TaggingToolContentCategory[];
  tags: TaggingToolTag[];
}

export type TaggingToolChannel = Pick<
  Channel,
  | 'id'
  | 'name'
  | 'short_name'
  | 'stream_url'
  | 'stream_url_low'
  | 'background_desktop_url'
  | 'background_mobile_url'
  | 'mobile_picture_url'
  | 'tablet_picture_url'
> & {
  logo_url: string;
  description_long: string;
  description_short: string;
  tags: unknown[];
};

export interface TaggingToolSoundpiece extends TaggingToolEntry {
  entity_name: string;
  properties: [];
  property_schema: [];
  title: string;
  description: string;
  broadcast_manager_sample_id: number;
  show_id: number;
  broadcast_manager_format_id: number;
  broadcast_manager_channel_id: string;
  broadcast_manager_id: number;
  audio_url: string;
  cover_url: string;
  published_at: string | null;
  contentful_teaser_id: string | null;
  author: string;
  content_category_slugs: TaggingToolContentCategory[];
  tags: TaggingToolTag[];
  duration: number;
}

// TODO: Consider usage of namespaces; they seem cool actually, especially importing
export namespace AppApi {
  interface Pagination {
    per_page: number;
    total: number;
    current_page: number;
  }

  export interface PaginatedDataType<T> {
    data: T;
    pagination: Pagination | null;
  }
}

// TODO: Consider more strict typing
export namespace JWPlayer {
  interface Playlist {
    title: string;
    mediaid: string;
    link: string;
    image: string;
    images: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
    pubdate: number;
    description: string;
    tags: string;
    sources: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
    tracks: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
    variations: Record<string, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
    duration: number;
  }

  export interface Media {
    title: string;
    description: string;
    kind: string;
    playlist: Playlist[];
    // eslint-disable-next-line camelcase
    feed_instance_id: string;
  }
}

export interface PlaylistHeaderData {
  id: string;
  name: string;
  description_long: string;
  logo_url: string;
  background_desktop_url: string;
  background_mobile_url: string;
}

export interface ChannelData {
  status: string;
  data: {
    id: string;
    name: string;
    logo_url: string;
    description_long: string;
    background_desktop_url: string;
    background_mobile_url: string;
  };
}

export type Program = {
  id: number;
  parent_id: number;
  title: string;
  url_title: string;
  assigned_command_type: string;
  assigned_command_identifier?: string;
  channel_id: string;
  cover_url: string;
};

export type Song = {
  id: number;
  title: string;
  duration: number;
  cover_url: string;
  hook_url: string;
  artists_full: string;
};

export type PlaylistSong = {
  type: 'music';
  play_from: string;
  audio_url: string;
  song: Song;
};

export type PlaylistNews = {
  type: 'news';
  play_from: string;
  audio_url?: string;
  program: Program;
};

export type PlaylistProgram = {
  type: 'program';
  play_from: string;
  audio_url: string;
  program: Program;
};

export type PlaylistItem = PlaylistSong | PlaylistNews | PlaylistProgram;

export type Category = {
  id: number;
  title: string;
  contentful_entity_id: string;
  contentful_type_id: string;
};

export type Sessions = {
  teasers: TeaserSectionEntry[];
  paginationData: {
    perPage: number;
    totalCount: number;
    areAllItemsLoaded: boolean;
    currentPage: number;
  };
};

export namespace QuizApi {
  export enum QuizApiErrors {
    NoUser = 'No User',
    IncompleteProfile = 'No Phone number',
    ServiceUnavailable = 'Service unavailable',
  }

  export type QuizError = {
    error: QuizApiErrors;
  };

  type DateString = string;

  type QuizOption = {
    id: number;
    text: string;
  };

  type QuizQuestion = {
    questionId: number;
    questionText: string;
    options: QuizOption[];
    questionEnd: DateString;
  };

  export interface QuizEntry {
    didUserAlreadyAnswer: boolean | null;
    question: QuizQuestion | null;
    nextQuestionStart: DateString | null;
  }

  export interface QuizCompleteData {
    message: string;
    wasCorrect: boolean;
  }

  export interface QuizWonData {
    slotId: string;
    userId: string;
  }

  export enum QuizState {
    DEFAULT = 'default',
    LOADING = 'loading',
    CORRECT = 'correct',
    WRONG_ANSWER = 'wrong_answer',
    ALREADY_ANSWERED = 'already_answered',
    NO_QUESTION = 'no_current_question',
    USER_NOT_LOGGED = 'user_not_logged',
    SWEEPSTAKE_NOT_WIN = 'sweepstake_not_win',
    SWEEPSTAKE_WIN = 'sweepstake_win',
    ERROR = 'error',
    INCOMPLETE_PROFILE = 'incomplete_profile',
    TIME_OUT = 'question_not_valid_based_on_current_time',
  }

  export type QuizAnswerProxyData = {
    error?: string;
    notAuthorized?: boolean;
    alreadyAnswered?: boolean;
    questionTimedOut?: boolean;
    wasCorrect?: boolean;
    hasWon?: boolean;
    completeProfile?: boolean;
    slotId?: string;
    userId?: string;
    text?: string;
    consent?: boolean;
  } | null;
}

export interface DeliveryOption {
  id: string | number | null;
  type: string;
  name: string;
  image?: string;
  image_mobile?: string;
}

export enum PrizeStatus {
  PENDING = 'pending',
  EXPIRED = 'expired',
  CONFIRMED = 'confirmed',
  CLOSED = 'closed',
}

export interface PrizeItems {
  name: string;
  url: string;
  barcode?: string;
  qrCode?: string;
  id?: string;
  wasScanned?: boolean;
}

export interface Ticket {
  barcode: string;
  id: string;
  name: string;
  primary: string;
  qr_code: string;
  scanned: boolean;
  url: string;
}

export interface TicketAndPrizeData extends ApiPrize {
  lottery_id: number | null;
  color: string;
  delivery_options: DeliveryOption[];
  event_id: 'string';
  guest_id: string;
  is_upgrade: boolean;
  items: Ticket[];
  'items-bundle': string;
  modus: string;
  prize_id: number;
  shop_link: string;
  tickettype_mobile_data?: {
    imageDesktop?: string;
    imageMobile?: string;
    description?: string;
  };
}

export interface ApiPrize {
  amount: number;
  id: number | string;
  lottery_id: number | null;
  type: 'event' | 'prize';
  status: PrizeStatus;
  name: string;
  description: string;
  expires_at: string;
  image: string | null;
  delivery_options: DeliveryOption[];
  items: PrizeItems[];
}

export enum AudioRaffle {
  Active = 'rt',
  Inactive = 'rf',
}
